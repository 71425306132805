<template>
    <div class="CityC">
        <el-container>
            <el-header style="padding: 0" height="100%">
              <vheader class="hidden-sm-and-down"></vheader>
              <ivheadera class="hidden-md-and-up"></ivheadera>
              <div class="top-img">
                <img class="hidden-sm-and-down" width="100%" src="../../assets/images/C/topimg.jpg" alt="">
                <img class="hidden-md-and-up" width="100%" src="../../assets/images/C/topimg-m.jpg" alt="">
                <div class="top-img-top">
                  <p>EVENTS</p>
                  <span>超级话题-大事件</span>
                </div>
              </div>
            </el-header>
            <el-main style="margin-top: 0" class="asaa">
              <div class="event-container">
                <div class="w asaa-mia">
                  <div class="ec-top-foots">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item>超级话题</el-breadcrumb-item>
                      <el-breadcrumb-item>大事件</el-breadcrumb-item>
                    </el-breadcrumb>
                  </div>
                  <div class="event-content">
                    <el-col :xs="24" :sm="24" :md="16">
                      <div class="event-list">
                        <ul>
                            <li v-for="(item,index) in allSelList" :key="index" @click="linkBtn(item)" :id="'anchor-' + index">
<!--                          <li v-for="(item,index) in allSelList" :key="index">-->
<!--                              <a :href="item.TOP == 'false' || item.BOTTOM == 'false'?item.PATH + item.INDEXPATH: '/Eventdetail?id='+ item.ID" target="_blank">-->
                                <div class="img">
                                  <img width="100%" :src="item.IMAGE" alt="">
                                </div>
                                <div class="txt">
                                  <div class="title">{{item.TITLE}} <span class="time">{{gettimes(item.STATEDATE).year || '-' }}-{{gettimes(item.STATEDATE).month || '-'}}</span></div>
                                  <div class="information">
                                    {{item.tEXPLAIN}}
                                  </div>
                                </div>
<!--                              </a>-->
                            </li>
                        </ul>
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="8">
                      <div class="select-card">
                        <div class="list-title">
                          CONTENTS <span>目录</span>
                          <i @click="openBtn" class="m-icon el-icon-arrow-up" :class="{'is-active': listShow}"></i>
                        </div>
                        <transition name="slideUp">
                        <div class="select-list" v-show="listShow">
                          <div class="more-btn">
                            <i @click="cutBtn" class="el-icon-caret-top"  :class="{ 'is-active':moreShow }"></i>
                          </div>
                          <ul>
                            <li v-for="(item, index) in selList" :key="index" @click="selChange(item.value)" v-anchor="item.value" :class="{ 'is-active': selNum == item.value }">
                              <i class="left-icon el-icon-caret-left"></i>
                                <div class="item-title">
                                  <span class="time">{{gettimes(item.STATEDATE).year || '-'}}年{{gettimes(item.STATEDATE).month || '-'}}月</span>
                                  <span class="title" ref="title" @mouseover="slideText(index)" @mouseout="slideOut(index)"><span class="txt">{{item.TITLE}}</span>
                                  </span>
                                </div>
                            </li>
                          </ul>
                          <div class="more-btn">
                            <i @click="moreBtn" class="el-icon-caret-bottom"  :class="{ 'is-active':moreShow }"></i>
                          </div>
                        </div>
                        </transition>
                        <a href="http://www.wicco.net/event">
                        <div class="wicco-link">
                          <i class="el-icon-s-order"></i>
                          <span>WICCO大事件</span>
                        </div>
                        </a>
                      </div>
                    </el-col>
                  </div>
                </div>
              </div>
              <gotop></gotop>
            </el-main>
            <el-footer>
              <vfooter class="hidden-sm-and-down"></vfooter>
              <ivfooter class="hidden-md-and-up"></ivfooter>
            </el-footer>
        </el-container>
    </div>
</template>
<script>
import { cityEvent, wiccoEvent } from '../../api'
import Gotop from '../../components/gotop'
export default {
  data () {
    return {
      allSelList: [
        {
          time: '2019年4月',
          title: '2019中国智慧城市论坛'
        },
        {
          time: '2019年4月',
          title: '创聚深圳'
        },
        {
          time: '2019年4月',
          title: '深圳智造，全球生长'
        },
        {
          time: '2019年4月',
          title: '“深圳市民讲外语”英语大赛网上赛场“深圳市民讲外语”英语大赛网上赛场'
        },
        {
          time: '2019年4月',
          title: '2019中国智慧城市论坛'
        },
        {
          time: '2019年4月',
          title: '创聚深圳创聚深圳创聚深圳创聚深圳创聚深圳创聚深圳创聚深圳创聚深圳创聚深圳创聚深圳'
        },
        {
          time: '2019年4月',
          title: '深圳智造，全球生长'
        },
        {
          time: '2019年4月',
          title: '“深圳市民讲外语”英语大赛网上赛场'
        },
        {
          time: '2019年4月',
          title: '“深圳市民讲外语”英语大赛网上赛场'
        },
        {
          time: '2019年4月',
          title: '“深圳市民讲外语”英语大赛网上赛场'
        },
        {
          time: '2019年4月',
          title: '“深圳市民讲外语”英语大赛网上赛场'
        }
      ],
      selList: [],
      selNum: 0,
      moreShow: false,
      listShow: true,
      listNum: 0
    }
  },
  methods: {
    linkBtn (item) {
      if(item.type){
        window.open(item.link_url? item.link_url : `http://www.wicco.net/detail/${item.ID}`)
      } else {
        if (item.TOP == 'false' && item.BOTTOM ==    'false') {
          window.open(item.PATH + item.INDEXPATH)
        } else {
          const { href } = this.$router.resolve({
            name: 'cityCC',
            query: {
              id: item.ID
            }
          })
          window.open(href, '_blank')
        }
      }
    },
    // 格式化默认时间
    gettimes (date) {
      function formatTen (num) {
        return num > 9 ? num : ('0' + num);
      }
      var newdate = new Date(date)
      var year = newdate.getFullYear()
      var month = newdate.getMonth() + 1
      var day = newdate.getDate()
      var hour = newdate.getHours()
      var minute = newdate.getMinutes()
      var second = newdate.getSeconds();
      var currentTime = {
        year: year,
        month: month,
        day: day,
        hour: formatTen(hour),
        minute: formatTen(minute),
        second: formatTen(second)
      }
      // var currentTime = 'new Date(' + year + ',' + month + ',' + day + ',' + hour + ',' + minute + ')'
      // var currentTime = new Date(year, month, day, hour, minute)
      return currentTime
    },
    openBtn () {
      this.listShow = !this.listShow
    },
    cutBtn () {
      if (this.listNum !== 0) {
        this.listNum -= 1
        if (this.listNum + 1 !== 0) {
          this.selList = this.allSelList.slice(0 + this.listNum, 8 + this.listNum)
        }
      }
    },
    moreBtn () {
      if (this.listNum + 8 < this.allSelList.length) {
        this.listNum += 1
        if (this.selList.length === 8) {
          if (this.listNum + 7 < this.allSelList.length) {
            this.selList = this.allSelList.slice(0 + this.listNum, 8 + this.listNum)
          }
        }
      }
    },
    selChange (i) {
      this.selNum = i
    },
    // 文字悬浮滚动
    slideText (i) {
      let obj = this.$refs.title[i]
      let distance = obj.firstChild.offsetWidth - obj.clientWidth + 2
      let time = distance / 70
      obj.firstChild.style.marginLeft = '-' + distance + 'px'
      obj.firstChild.style.transitionProperty = 'margin-left'
      obj.firstChild.style.transitionDuration = time + 's'
      obj.firstChild.style.transitionTimingFunction = 'linear'
    },
    slideOut (i) {
      let obj = this.$refs.title[i]
      obj.firstChild.style.marginLeft = '0'
      obj.firstChild.style.transitionDuration = '0.5s'
    },
    // 适配右边悬浮
    scrollListener () {
      let obj = $('.select-card')
      if (document.documentElement.scrollTop < 300) {
        obj.css('top', 'unset')
      } else {
        obj.css('top', '1.5rem')
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollListener)
    // cp大事件
    let p1 = cityEvent()
    // wicco大事件
    let p2 = wiccoEvent()
    Promise.all([p1,p2]).then(([cityData, wiccoData]) => {
      let wiccoEvent = wiccoData.data.data.map(item => {
        return {
          IMAGE: item.img_url,
          TITLE: item.name,
          STATEDATE: item.modified_time,
          tEXPLAIN: item.introduction,
          type: item.type,
          link_url: item.link_url,
          ID: item.id
        }
      })
      // cityData.data.forEach(item => {
      //   let date = this.gettimes(item.STATEDATE)
      //   item.STATEDATE = `${date.year}-${date.month}-${date.day} ${date.hour}:${date.minute}:${date.second}`
      // })
      this.allSelList = cityData.data.concat(wiccoEvent)
      //   .sort((a,b) => {
      //   return Date.parse(b.STATEDATE) - Date.parse(a.STATEDATE)
      // })
      this.allSelList.forEach((item,index) => {
        item.value = index
      })
      this.selList = this.allSelList.slice(0, 8)
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollListener)
  },
  components: {
    Gotop
  }
}
</script>

<style lang="less">
.CityC {
  .el-main {
    overflow: initial;
  }
  .el-popover .el-popover__title {
    height: 20px;
    font-size: 0.3rem;
    text-align: center;
    align-items: center;
    line-height: 34px;
    color: #6a6969;
  }
  .asaa-mia {
    position: relative;
  }
  .asaa .asaa-mia .ec-top-foots {
    position: absolute;
    top: -42px;
    left: 20px;
    background: rgba(0, 182, 205,1);
    min-width: 6.5rem;
    height: 0.78rem;
    align-items: center;
  }
  .asaa .asaa-mia .ec-top-foots .el-breadcrumb {
    line-height: 0.78rem;
    padding-left: 10px;
  }
  .el-breadcrumb__inner{
    color: #fff;
  }
  .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__inner a {
    color: #fff;
    font-weight: 400;
  }
  .el-breadcrumb__item .el-breadcrumb__separator {
    color: #fff;
  }
  .el-breadcrumb__inner a:hover {
    color: #fff600;
  }
  @media screen and (max-width: 1057px) {
    .asaa .asaa-mia .ec-top-foots {
      display: none;
    }
  }
}
</style>
<style lang="less" scoped>
.CityC{
  // 2021.10.14修改
  .wicco-link{
    background: linear-gradient(#37cadd,#37d6cd);
    padding: 0.25rem 0;
    color: #fff;
    text-align: center;
    font-size: 0.3rem;
    border-radius: 0.2rem;
    cursor: pointer;
    margin-top: 0.1rem;
    span{
      margin-left: 0.1rem;
    }
  }
  .el-main {
    overflow: initial;
    padding: 0;
  }
  .asaa .asaa-mia .header-title{
    margin: 0.6rem 0;
  }
  .asaa .asaa-mia {
    padding: 20px;
  }
  .top-img{
    position: relative;
    img{
      display: block;
    }
  }
  .top-img-top {
    position: absolute;
    top: 45%;
    left: 5%;
    color: #FFFFFF;
    text-align: left;
  }
  .top-img-top p {
    font-size: 0.6rem;
    font-weight: 600;
  }
  .top-img-top span {
    font-size: 0.45rem;
    font-weight: 300;
  }
  .top-img .top-img-top:after {
    content: '';
    position: absolute;
    left: 0;
    top: -16px;
    height: 1px;
    width: 40%;
    background: #FFF;
  }
  .event-container{
    .event-content{
      display: inline-block;
      padding-bottom: 0.5rem;
      width: 100%;
      .event-list{
        ul{
          margin: 0.5rem 0;
          li{
            background: #fff;
            &:not(:first-child){
              margin-top: 1rem;
            }
            .txt{
              margin-top: 0.35rem;
              text-align: left;
              .title{
                font-size: 0.5rem;
                color: #0FB7D1;
                font-weight: bold;
                .time{
                  margin-left: 0.2rem;
                  font-size: 0.25rem;
                  color: #464646;
                }
              }
              .information{
                margin-top: 0.1rem;
                color: #464646;
                font-size: 0.25rem;
                display:-webkit-box;
                -webkit-line-clamp:3;
                -webkit-box-orient:vertical;
                overflow:hidden;
              }
            }
          }
        }
      }
      .select-card{
        background: #fff;
        margin: 0.5rem 0 0.5rem 0.5rem;
        text-align: left;
        position: fixed;
        width: 7rem;
        .list-title{
          text-align: left;
          font-size: 0.5rem;
          color: #0FB7D1;
          font-weight: bold;
          .m-icon{
            display: none;
          }
          span{
            color: #464646;
          }
        }
        .select-list{
          margin-top: 0.2rem;
          ul{
            transition: all 0.3s ease-out 0s;
            li{
              position: relative;
              cursor: pointer;
              padding: 0.2rem 0;
              border-bottom: 1px solid #87DBE8;
              margin: 0 0.3rem;
              &:first-child{
                border-top: 1px solid #87DBE8;
              }
              .left-icon{
                color: #0FB7D1;
                display: none;
              }
              .item-title{
                font-size: 0.25rem;
                color: #464646;
                padding-right: 0.2rem;
                .time{
                  width: 28%;
                  display: inline-block;
                  color: #0FB7D1;
                  vertical-align: middle;
                }
                .title{
                  width: 72%;
                  display: inline-block;
                  vertical-align: middle;
                  overflow: hidden;
                  text-overflow:ellipsis;
                  white-space: nowrap;
                  .txt{
                    /*margin-left: 0px;*/
                    /*transition-property: margin-left;*/
                    /*transition-duration: 0.5s;*/
                    /*transition-timing-function: linear;*/
                  }
                }
              }
            }
            .is-active{
              background: #0FB7D1;
              &:before{
                content: '';
                width: 0.3rem;
                height: 105%;
                background: #0FB7D1;
                position: absolute;
                top: -1px;
                left: -0.3rem;
                border-top: 1px solid #87DBE8;
                border-bottom: 1px solid #87DBE8;
              }
              .item-title{
                color: #fff;
                .time{
                  color: #fff;
                }
              }
              .left-icon{
                display: inline-block;
                position: absolute;
                left: -0.44rem;
                top: 0.28rem;
              }
            }
          }
          .more-btn{
            margin-top: 0.1rem;
            text-align: center;
            i{
              transition: all 0.4s ease-out 0s;
              cursor: pointer;
              font-size: 0.28rem;
              color: #0FB7D1;
            }
            .is-active{
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
  @media screen and (max-width:990px){
    .asaa .asaa-mia{
      padding: 20px 15px;
    }
    .event-container{
      background: #f0f0f0;
      .event-content{
        display: flex;
        flex-direction: column-reverse;
        .select-card{
          top: 0 !important;
          margin: 0;
          position: relative;
          width: 100%;
          .list-title{
            padding: 0.1rem 0.3rem 0 0.3rem;
            text-align: left;
            font-size: 0.6rem;
            .m-icon{
              display: inline-block;
              float: right;
              margin-top: 0.2rem;
              font-size: 0.5rem;
              transition: all 0.3s ease-out 0s;
            }
            .is-active{
              transform: rotate(180deg);
            }
          }
          .select-list{
            ul li .item-title{
              font-size: 0.35rem;
            }
          }
        }
        .event-list{
          ul{
            li{
              .txt{
                padding:0 0.4rem 0.3rem 0.4rem;
                .title .time{
                  display: block;
                  margin-left: 0;
                  font-size: 0.3rem;
                  color: #c2c2c2;
                  margin-top: 0.1rem;
                }
                .information{
                  margin-top: 0.1rem;
                  font-size: 0.3rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .slideUp-enter-active {
    animation: slideInUp .3s;
  }
  .slideUp-leave-active {
    animation: slideInUp .3s reverse;
  }
  @keyframes slideInUp {
    from {
      -webkit-transform: translate3d(0, 10%, 0);
      transform: translate3d(0, 10%, 0);
      visibility: visible;
    }

    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
}
</style>
